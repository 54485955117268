import React, { CSSProperties, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { SVG, Button } from "~components";
import { getButtonProps } from "~utils";
import { ILinkExternal, ILinkInternal } from "~schemas";

import * as styles from './styles.module.scss';

type StatusAccordionProps = {
  title: string;
  content: string;
  status: 'comingSoon' | 'live' | 'none';
  open?: boolean;
  onChange?: (open: boolean) => void;
  openByDefault: boolean;
  links: Array<ILinkExternal | ILinkInternal>;
  className?: string;
}

const StatusAccordion = (props: StatusAccordionProps) => {
  const { title, content, status = `none`, openByDefault, links, className } = props;
  const [isOpen, setIsOpen] = useState<boolean>(openByDefault);

  const [contentHeight, setContentHeight] = useState(60);
  const contentRef = useRef<HTMLDivElement>();

  const calculateContentHeight = () => {
    if (contentRef.current) {
      const height = contentRef.current.offsetHeight;
      setContentHeight(height);
    }
  };

  const handleOpen = () => {
    setIsOpen((prev) => {
      if (props.onChange) props.onChange(!prev);
  
      return !prev;
    });
  }

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  const statusText = status === `comingSoon` ? `Launching soon` : `Live`;
  const isAccordionOpen = props.open ?? isOpen;
  const icon = isAccordionOpen ? `minus` : `plus`;

  return (
    <div className={cn(styles.container, className)}>
      <section className={styles.accordionSection} onClick={handleOpen}>
        <div className={styles.accordionButtonTitleStatus}>
          <h3>{title}</h3>
          {status !== `none` && (
            <div className={styles.accordionStatus}>
              <div className={styles.statusDot} style={{ '--status-color': status === `comingSoon` ? `#ffa674` : `#bdffa6`} as CSSProperties} />
              <span className="caption">{statusText}</span>
            </div>
          )}
        </div>
        <div className={styles.icon}>
          <SVG svg={icon} />
        </div>
      </section>
      <div className={styles.contentContainer} style={{ height: `${isAccordionOpen ? contentHeight : 0}px` }}>
        <div className={styles.content} ref={contentRef as any}>
          {content && <p className="b2">{content}</p>}
          {links?.[0] && (
            <ul className={cn(styles.links)}>
              {links.map((link, i) => {
                const buttonProps = getButtonProps(link);
                return (
                  <li key={i} className="accordionLink">
                    {link.title && (
                      <Button
                        key={link._key}
                        className={styles.button}
                        {...buttonProps}
                        variant="secondary"
                        inheritColor
                      >
                        {link.title}
                      </Button>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusAccordion;
