import { useEffect, useState } from "react";

const useLcdTokenPrice = (decimals = 3) => {
  const [lcdPrice, setLcdPrice] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);

  const getPrice = async () => {
    const ENDPOINT = `https://api.coingecko.com/api/v3/coins/lucidao/tickers`;
    const response = await fetch(ENDPOINT, {
      method: `GET`,
      headers: { Accept: `application/json` }
    });
    const token = await response.json();
    return token.tickers[0].converted_last.usd;
  };

  const getLCDPrice = async (dec) => {
    try {
      const res = await getPrice();
      return res.toFixed(dec);
    } catch (e) {
      console.error(`Problem fetching LCD price: `, e);
      return null;
    } finally {
      setHasFetched(true);
    }
  };

  useEffect(() => {
    const fetchLCDPrice = async () => {
      const lcdPriceResponse = await getLCDPrice(decimals);
      setLcdPrice(lcdPriceResponse);
    };

    fetchLCDPrice();
  }, []);

  return { lcdPrice, hasFetched };
};

export default useLcdTokenPrice;
