// extracted by mini-css-extract-plugin
export var container = "styles-module--container--72a1d";
export var content = "styles-module--content--5b332";
export var decrease = "styles-module--decrease--38cc5";
export var desktop = "1440px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var percentage = "styles-module--percentage--71acf";
export var price = "styles-module--price--fd354";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var values = "styles-module--values--0c59d";