// extracted by mini-css-extract-plugin
export var accordionButtonTitleStatus = "styles-module--accordionButtonTitleStatus--6475a";
export var accordionSection = "styles-module--accordionSection--4eb22";
export var accordionStatus = "styles-module--accordionStatus--63fe1";
export var container = "styles-module--container--22585";
export var content = "styles-module--content--c21c4";
export var contentContainer = "styles-module--contentContainer--69dbb";
export var desktop = "1440px";
export var giant = "2200px";
export var icon = "styles-module--icon--904ed";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var links = "styles-module--links--224ff";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var statusDot = "styles-module--statusDot--dbc94";
export var tablet = "768px";