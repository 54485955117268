import React from "react";
import cn from "classnames";
import { Button, Tooltip, SVG } from "~components";
import { useBreakpoints, useLcdTokenPrice } from "~hooks";

import * as styles from "./styles.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { IAltImage } from "~schemas";

interface IProps {
  className?: string;
  tokenImage?: IAltImage;
}

const BuyLCDCard = ({ className, tokenImage }: IProps) => {
  const { breakpoints } = useBreakpoints();
  const { lcdPrice, hasFetched } = useLcdTokenPrice(6);

  const isTablet = breakpoints.tablet;

  // dev note: use this variable to apply conditional styling to the polygon svg when there is a decrease in value
  const decrease = false;

  return hasFetched && (
    <div className={cn(styles.container, className)}>
      <div className={styles.content}>
        <p className="caption">Buy LCD on Polygon</p>

        <div className={styles.values}>
          <div className={styles.price}>
            {tokenImage?.asset?.gatsbyImageData && (
              <figure>
                <GatsbyImage
                  style={{
                    width: `18px`,
                    aspectRatio: `1`,
                    borderRadius: `0.75rem`,
                    position: `relative`,
                    bottom: `1px`
                  }}
                  alt={tokenImage?.altText || "LCD Icon"}
                  image={tokenImage?.asset?.gatsbyImageData}
                />
              </figure>
            )}
            <Tooltip content="Data provided by CoinGecko" direction="right">
              <h3 className={cn(isTablet ? "b1" : "h3")}>${lcdPrice || `Error fetching price`}</h3>
            </Tooltip>
          </div>

          {decrease && (<p
            className={cn("b1", styles.percentage, {
              [styles.decrease]: decrease
            })}
          >
            <SVG svg="polygon" />
            4.6%
          </p>)}
        </div>
      </div>

      <div>
        <Button href="https://swap.defillama.com/?chain=polygon&from=0x0000000000000000000000000000000000000000&to=0xc2a45fe7d40bcac8369371b08419ddafd3131b4a" variant="secondary" lightTheme>
          Buy LCD
        </Button>
      </div>
    </div>
  );
};

export default BuyLCDCard;
