import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
  direction: string;
}

const Tooltip = ({ content, children, direction = `right` }: IProps) => (
  <div className={styles.tooltipWrapper}>
    {children}
    {typeof content === `string` ? (
      <div className={cn(styles.tip, styles[direction])}>
        <p>{content}</p>
      </div>
    ) : (
      <div className={cn(styles.tip, styles[direction])}>{content}</div>
    )}
  </div>
);

export default Tooltip;
