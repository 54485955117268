import React from "react";
import cn from "classnames";
import { Footer, Header, NoJs, Theme } from "~components";
import * as styles from "./styles.module.scss";
import "~node_modules/modern-normalize/modern-normalize.css";
import "~styles/global.css";

interface IProps {
  children: React.ReactNode;
  className?: string;
  location: {
    pathname: string;
  };
  pageContext: {
    id?: string;
  };
}

const Layout = ({ children, className, location, pageContext }: IProps) => {
  const { id } = pageContext;

  return (
    <>
      {id && <Header location={location} />}
      <NoJs />
      <Theme />
      <div className={cn(styles.container, className)}>{children}</div>
      {id && <Footer />}
    </>
  );
};

export default Layout;
